import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm'

export const query = graphql`
  query Contact {
    file(relativePath: { eq: "contact/contact-bg.png" }) {
      id
      childImageSharp {
        fluid(maxWidth: 800, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dataJson {
      contactInfo {
          australia {
            title
            addressLine1
            addressLine2
            addressLine3
            phone
            phoneLink
            link
          }
          australia_2 {
            addressLine1
            addressLine2
            addressLine3
            link
          }
          singapore {
            title
            addressLine1
            addressLine2
            addressLine3
            link
          }
        }
    }
  }
`

export default function contact({ data: { file, dataJson: { contactInfo: { australia, australia_2, singapore } } } }) {
  const backgroundFluidImageStack = [
    file.childImageSharp.fluid,
    `linear-gradient(135deg, rgba(242,247,255,1) 30%, rgba(242,247,255,0) 100%)`
  ].reverse()

  return (
    <Layout title='Contact' url='/contact' page='contact'>
      <BackgroundImage fluid={backgroundFluidImageStack} className="contact-bg" style={{ backgroundColor: 'var(--brand-primary)', backgroundBlendMode: 'screen' }}>
        <h1 className='ics__h1'>Contact us</h1>

        <div className="contact__info">
          <div>
            <h2 className='ics__h3'>{australia.title}</h2>
            <a className="ics__p-hyperlink-hide" href={australia.link} target="_blank" rel="noreferrer noopener">
              {australia.addressLine1}<br />
              {australia.addressLine2}<br />
              {australia.addressLine3}<br />
            </a>
            <a className="ics__p-hyperlink-hide" href={australia.phoneLink} target="_blank" rel="noreferrer noopener">
              {australia.phone}
            </a>
            <br />
            <br />
            <br/ >
            <a className="ics__p-hyperlink-hide" href={australia_2.link} target="_blank" rel="noreferrer noopener">
              {australia_2.addressLine1}<br />
              {australia_2.addressLine2}<br />
              {australia_2.addressLine3}<br />
            </a>
            <br />
          </div>
          <div>
            <h2 className='ics__h3'>{singapore.title}</h2>
            <a className="ics__p-hyperlink-hide" href={singapore.link} target="_blank" rel="noreferrer noopener">
              {singapore.addressLine1}<br />
              {singapore.addressLine2}<br />
              {singapore.addressLine3}
            </a>
          </div>
        </div>
        <ContactForm />
      </BackgroundImage>
    </Layout>
  )
}
