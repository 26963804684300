import React, { useState } from 'react'
import axios from 'axios'

import Loading from './Loading'

import check from '../assets/images/icons/check-circle-fill.svg'
import crossMark from '../assets/images/icons/issue-opened.svg'

export default function ContactForm() {
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  })
  const [formStatus, setFormStatus] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onChangeHandler = ({ target: { name, value } }) => {
    setForm({
      ...form,
      [name]: value,
    })
  }

  let stopLoad1
  let stopLoad2

  const closeForm = () => {
    window.clearTimeout(stopLoad1)
    window.clearTimeout(stopLoad2)
    setIsLoading(false)
    setFormStatus('')
  }

  const submitForm = (e) => {
    e.preventDefault()
    setIsLoading(true)
    axios
      .post('https://formspree.io/mqkyjegl', form)
      // .post('http://httpstat.us/500', form)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false)
          setFormStatus('SUCCESS')
          setForm({ firstName: '', lastName: '', email: '', message: '' })
          stopLoad2 = setTimeout(() => {
            closeForm()
          }, 3000)
        }
      })
      .catch((err) => {
        setIsLoading(false)
        setFormStatus('ERROR')
        setForm({ firstName: '', lastName: '', email: '', message: '' })
        stopLoad2 = setTimeout(() => {
          closeForm()
        }, 3000)
        throw err
      })
  }

  return (
    <div
      className='contact-form'
      id='contact-form'>
      <form
        onSubmit={submitForm}>
        <input
          type='text'
          name='firstName'
          placeholder='First Name'
          value={form.firstName}
          onChange={onChangeHandler}
          className="ics__p"
          required
        />
        <input
          type='text'
          name='lastName'
          placeholder='Last Name'
          value={form.lastName}
          onChange={onChangeHandler}
          className="ics__p"
          required
        />
        <input
          type='email'
          name='email'
          placeholder='Email'
          value={form.email}
          onChange={onChangeHandler}
          className="ics__p"
          required
        />
        <textarea
          name='message'
          placeholder='Message'
          value={form.message}
          onChange={onChangeHandler}
          className="ics__p"
          required
        />
        <button type='submit' className={`button-submit ${isLoading && 'select-none'}`}>
          {isLoading ? <p className='loading'>Loading...</p> : <p>Submit</p>}
        </button>

        <div
          className={`form-validate ${!isLoading && formStatus ? 'fade-in' : 'fade-out'
            }`}>
          {!isLoading && formStatus === 'ERROR' && (
            <div
              className={`${!isLoading && formStatus === 'ERROR' ? 'fade-in' : 'fade-out'
                }`}>
              <img src={crossMark} alt='Cross mark' />
              <div>
                <p style={{ color: 'crimson' }} className='ics__p'>There was an error!</p>
              </div>
            </div>
          )}

          {!isLoading && formStatus === 'SUCCESS' && (
            <div
              className={`${!isLoading && formStatus === 'SUCCESS'
                ? 'fade-in'
                : 'fade-out'
                }`}>
              <img src={check} alt='Check mark' />
              <div>
                <p className='ics__p'>Enquiry has been sent.</p>
                <p className='ics__p'>We will be in touch shortly</p>
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  )
}
